import { Cmp, Mod, state } from ":mods";
import { adminApis } from "../../apis";
import { Match, Switch, batch, createEffect, splitProps } from "solid-js";
import { useParams } from "@solidjs/router";
import { AResource, EditResourcesViewProps } from "../../model";
import "../../css/resources.css";
import { ResourceForm } from "./resource-form";

export function EditResources(props: EditResourcesViewProps) {
  const [local, others] = splitProps(props, ["$", "stageID"]);
  const params = useParams();
  const stageID = local.stageID ?? params.id; //local.$.actions.getStageSelected();
  const $list = state.createAsync([adminApis.listGet, stageID]);
  local.$.actions.setLayout({
    title: "Edit Resources for " + Mod.Courses.CONST.STAGES[stageID],
  });

  function getPayload(values: AResource) {
    const payload = new FormData();
    const { image, ...v } = values;
    payload.append("data", JSON.stringify(v));
    payload.append("image", image as any);
    return payload as any;
  }

  function onCreate(values: AResource) {
    const payload = getPayload(values);
    adminApis
      .listPost(stageID, payload as any)
      .then((obj) => {
        console.log("obj is :: ", obj);
        $list.set((s) => ({
          ...s,
          data: {
            ...s.data,
            resources: [...s.data.resources, obj as any],
          },
        }));
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        local.$.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onUpdate(values: AResource, index: number) {
    const payload = getPayload(values);
    adminApis
      .detailsPut(values.id, payload)
      .then(() => {
        $list.set((s) => {
          const new_arr = [...s.data.resources];
          new_arr[index] = { ...new_arr[index], ...values };
          return { ...s, data: { ...s.data, resources: new_arr } };
        });
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        local.$.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onDelete(indices: number[]) {
    const ids = indices.map((i) => $list.value.data.resources[i].id);
    const remove = [] as number[];
    adminApis.detailsDelete(ids).then((obj) => {
      obj.forEach((item) => {
        if (item.error) {
          local.$.actions.pushToast({
            type: "error",
            message: item.error,
          });
        }
        remove.push(item.id);
      });
      if (remove.length > 0) {
        $list.set((s) => ({
          ...s,
          data: { ...s.data, resources: s.data.resources.filter((x, i) => !remove.includes(x.id)) },
        }));
        local.$.actions.pushToast({
          type: "success",
          message: "Deleted " + remove.length + " resources",
        });
      }
    });
  }

  return (
    <Switch fallback={<div>unknown resource state </div>}>
      <Match when={$list.state.loading}>Loading...</Match>
      <Match when={$list.state.failed}>An error occured while fetching stage data {$list.error} ...</Match>
      <Match when={$list.state.success}>
        <main class="flex flex-col w-full h-full">
          <Cmp.List.Table
            rows={$list.value.data.resources}
            rowClass="w-full flex flex-row  text-20px"
            rowContainerClass="w-full flex flex-row rounded-lg px2 text-20px"
            colsClasses={{
              id: "!w-10% text-start capitalize",
              name: "!w-90% text-start capitalize",
              // actions: "!w-30% capitalize",
            }}
            events={{
              onAllRows({ event, selected }) {
                console.log("action for all is ", event, selected);
                if (event === "delete") {
                  onDelete(selected);
                } else if (event === "create") {
                  local.$.actions.pushModal({
                    title: "Create New Resource",
                    element: (event) => (
                      <ResourceForm
                        stage={stageID}
                        onDone={(values, payload) => {
                          event("close");
                          onCreate(values as any);
                        }}
                      />
                    ),
                  });
                }
              },
              onSingleRow({ event, item, index, rows }) {
                if (event === "delete") {
                  onDelete([index]);
                } else if (event === "clicked") {
                  // onClickedItem(item.id);
                } else if (event === "create") {
                  local.$.actions.pushModal({
                    title: `Create New Resource (Template of ${item.name})`,
                    element: (event) => (
                      <ResourceForm
                        stage={stageID}
                        resource={item.id}
                        onDone={(values) => {
                          event("close");
                          onCreate(values as any);
                        }}
                      />
                    ),
                  });
                } else if (event === "edit") {
                  local.$.actions.pushModal({
                    title: `Update Resource ${item.name}`,
                    element: (event) => (
                      <ResourceForm
                        stage={stageID}
                        resource={item.id}
                        buttonMsg="Update"
                        onDone={(values) => {
                          event("close");
                          onUpdate(values as any, index);
                        }}
                      />
                    ),
                  });
                }
              },
            }}
          />
        </main>
      </Match>
    </Switch>
  );
}
